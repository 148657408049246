.pl1 {
	justify-content: space-around;
  display: flex;
  margin: 1.5em;
  width: 6em;
  height: 6em;
}
.pl1__a, .pl1__b, .pl1__c {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  transform-origin: 50% 100%;
  animation: bounce1 1s linear infinite;
}

.pl1__a {
  background: #F4AB29;
}
.pl1__b {
  background: #F4AB29;
  animation-delay: 0.1s;
}
.pl1__c {
  background: #F4AB29;
  animation-delay: 0.2s;
}

/* Animations */
@keyframes bounce1 {
	from, to {
		transform: translateY(0) scale(1,1);
		animation-timing-function: ease-in;
  }
	45% {
		transform: translateY(5em) scale(1,1);
		animation-timing-function: linear;
  }
	50% {
		transform: translateY(5em) scale(1.5,0.5);
		animation-timing-function: linear;
  }
	55% {
		transform: translateY(5em) scale(1,1);
		animation-timing-function: ease-out;
  }
}